<template>
  <div>
    <!-- pc -->
    <div class="pc container">
      <div class="h5 main" style="padding:60px 0 28px 0">비밀번호 변경</div>
      <div class="panel-container">
        <div class="grid">
          <template v-for="(form,idx) in formData">
            <div class="subtitle5 main" style="line-height: 48px"
                 :key="'form-'+idx">{{ form.label }}</div>
            <lp-text  :key="'form-text-'+idx" v-bind.sync="form"></lp-text>
          </template>
        </div>
        <div style="width:100%;text-align: center;margin-top:56px">
          <button class="button is-primary" style="width:242px" @click="clickUpdate">수정</button>
        </div>
      </div>
    </div>
    <!-- mobile -->
    <div class="mobile container">
      <div class="h7 main" style="padding:24px 0 16px 0">비밀번호 변경</div>
      <div class="panel-container">
        <div style="width:100%">
          <template v-for="(form,idx) in formData">
            <div class="subtitle7 main" style="margin-bottom:8px"
                 :key="'form-'+idx">{{ form.label }}</div>
            <lp-text style="margin-bottom:12px" :key="'form-text-'+idx" v-bind.sync="form"></lp-text>
          </template>
        </div>
        <div style="width:100%;text-align: center;margin-top:32px">
          <button class="button is-primary" style="width:100%;height:48px" @click="clickUpdate">수정</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import LpText from "../component/LpText";
  import FormMixin from "../../mixins/FormMixin";
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  export default {
    name: "password",
    components: {LpText},
    mixins: [
      FormMixin,
      UserAPIMixin
    ],
    data () {
      return {
        formData: [
          {
            component_name: 'lp-text',
            label: '현재 비밀번호',
            placeholder: '비밀번호를 입력해주세요',
            type: 'password',
            field: 'password',
            value: '',
            maxLength: 128,
            validate: {
              valid_required: true
            },
            errorMsg: ''
          },
          {
            component_name: 'lp-text',
            label: '새 비밀번호',
            placeholder: '비밀번호를 입력해주세요',
            type: 'password',
            field: 'new_password',
            value: '',
            maxLength: 128,
            validate: {
              valid_required: true
            },
            errorMsg: ''
          },
          {
            component_name: 'lp-text',
            label: '새 비밀번호 확인',
            placeholder: '비밀번호를 입력해주세요',
            type: 'password',
            field: 'new_password_re',
            value: '',
            maxLength: 128,
            validate: {
              valid_required: true
            },
            errorMsg: ''
          },
        ]
      }
    },
    methods: {
      clickUpdate() {
        let params = this.getParamData(this.formData);
        if(!params.enable) {
          return;
        }
        if(params.params.new_password !== params.params.new_password_re) {
          this.formData[2].errorMsg = '새 비밀번호 확인값이 일치하지 않습니다'
          this.toast(this.formData[2].errorMsg);
          return;
        }
        this.$axios.post('auth/user/login', {
          username: this.user.username,
          password: params.params.password
        }).then(()=>{
          this.request.user.patch('', {password: params.params.new_password}).then((res)=>{
            this.$store.commit('setUser', res.data.user);
            this.setAuthHeader();
            this.toast('비밀번호가 변경되었습니다.');
            this.routerPush('my_service');
          });
        }).catch(() => {
          this.toast('기존 비밀번호가 맞지 않습니다.');
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .pc
    .panel-container
      padding 52px 294px
  .grid
    grid-template-columns repeat(2, 1fr)
    grid-row-gap 16px

</style>
